import React from 'react'

import * as styles from './product-page.module.css'
import ProductImageCarousel from './product-image-caroussel'
import Checkout from './checkout'

const ProductPage = ({ images, product }) => {
  if (!images) return null
  if (!product) return null

  var formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
  });
  
  return (
    <div className={styles.row}>
        <div className={styles.caroussel}>
            <ProductImageCarousel images={images} />
        </div>
        <div key={product.slug} className={styles.productInformationColumn}>
          <h1>{product.name}</h1>
          <h1>{formatter.format(product.price)}</h1>
          <div className={styles.checkout_before}>
              <Checkout />
          </div>
          <div
          className={styles.productInformation}
          dangerouslySetInnerHTML={{
          __html: product.description?.childMarkdownRemark?.html,
          }}
          /> 
          <div className={styles.checkout_after}>
              <Checkout />
          </div>
        </div>
    </div>
  )
}

export default ProductPage



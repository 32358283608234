import React from "react"

const buttonStyles = {
  fontSize: "13px",
  textAlign: "center",
  color: "#000",
  backgroundColor: "#e68f72",
  padding: "12px 60px",
  boxShadow: "2px 5px 10px rgba(0,0,0,.1)",
  borderRadius: "6px",
  letterSpacing: "1.5px",
  fontWeight: "bold",
}

const Checkout = () => {
  return (
    <form action="https://buy.stripe.com/bIY4hXgv18EfewU5kk">
      <button
        style={buttonStyles}
      >
        KOPEN
      </button>
    </form>
  )
}

export default Checkout
import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import * as styles from './product-image-caroussel.module.css'

const ProductImageCarousel = ({ images }) => {
  const settings = {
    arrows: true,
    infinite: false,
    autoplay: false,
    dots: true,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: function (i) {
      return (
        <a>
          <img
            src={images[i].fluid.src}
            height="100%"
            width="100%"
          />
        </a>
      );
    },
  }
  
  return (
    <Slider {...settings} className={styles.slider}>
        {images.map((image) => {
          return (
            <div key={image.contentful_id}>
              <GatsbyImage alt="" image={image.gatsbyImageData}/>
            </div>
          )
        })}
    </Slider>
  )
}

export default ProductImageCarousel
import React from 'react'

import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import Seo from '../components/seo'

import ProductPage from '../components/product-page'
import ContainerProduct from '../components/container-product'

//Todo: fix the nodes[0]
class ProductTemplate extends React.Component {
  render() {
    const product = get(this, 'props.data.contentfulProduct')
    const images = product.additionalImages
    return (
    <Layout location={this.props.location}>
        <Seo
          title={product.name}
          description={product.description.childMarkdownRemark.excerpt}
        />
        <ContainerProduct>
            <ProductPage images={images} product={product} />
        </ContainerProduct>    
    </Layout>
    )
  }
}

export default ProductTemplate

export const query = graphql`
query ProductBySlug(
    $slug: String!
    $previousProductSlug: String
    $nextProductSlug: String
    ) {
        contentfulProduct(slug: {eq: $slug }) {
            slug
            name
            price
            mainImage {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
            }
            additionalImages {
                fluid(maxWidth: 300, quality: 50) {
                  src
                }
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
                contentful_id
            }
            description {
                childMarkdownRemark {
                    excerpt
                    html
                }
            }
        }
        previous: contentfulProduct(slug: { eq: $previousProductSlug }) {
            slug
            name
        }
        next: contentfulProduct(slug: { eq: $nextProductSlug }) {
            slug
            name
        }
    }    
`